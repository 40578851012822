<template>
  <div>
    <div class="mb-2">
      <b-button variant="primary-custom" @click="$router.go(-1)"
        ><fa-icon icon="arrow-left" class="mr-2"></fa-icon>Voltar</b-button
      >
    </div>
    <AddEditForm v-if="ready"/>
  </div>
</template>

<script>
export default {
  data(){
    return{
      ready: false
    }
  },
  components: {
    AddEditForm: () => import("./components/AddEditForm.vue"),
  },
  async mounted() {
    await this.$store.dispatch("users/users_init");
    this.ready = true;
  },
};
</script>

<style>
</style>